import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import TextPageTemplate from "../components/TextPageTemplate";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const Udrzba = ({ data }) => {
  const { frontmatter } = data.page;
  const { service } = data.idnexPage.frontmatter;
  return (
    <Layout>
      <TextPageTemplate
        title={frontmatter.title}
        description={frontmatter.description}
        footerDescription={frontmatter.footerDescription}
        footerTitle={frontmatter.footerTitle}
        content={frontmatter.content}
      />
      <section className="section-b fix-width">
        <AliceCarousel
          mouseTracking
          autoPlayInterval={5000}
          infinite
          responsive={{
            0: { items: 1 },
            961: { items: 3 },
            1222: { items: 3 },
            1555: { items: 4 },
          }}
          disableDotsControls={true}
          disableButtonsControls={true}
          autoPlay={true}
          items={service.gallery.map((reference, i) => {
            return (
                <div className="item" key={'reference-carousel-item' + i}>
                  <PreviewCompatibleImage imageInfo={{image: reference.image, alt: reference.alt}} />
                </div>
              )
          })}
        />
      </section>
    </Layout>
  );
};

Udrzba.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default Udrzba;
export const pageQuery = graphql`
  query udrzbaTemplate {
    page: markdownRemark(frontmatter: { templateKey: { eq: "udrzba" } }) {
      frontmatter {
        metadata {
          title
          description
        }
        title
        description
        footerTitle
        footerDescription
        content {
          title
          description
          blocks {
            title
            text
          }
        }
      }
    }
  
    idnexPage: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        service {
          title
          description
          gallery{
						image {
          		childImageSharp {
                gatsbyImageData(quality: 100, width:500, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
              }
        		}
            alt
          }
        }
      }
    }
  }
`;


